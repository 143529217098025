import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, TextField, Button, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import { Account } from "../hooks/useWalletList";
import { PublicClient } from "viem";
import { adaptViemWallet, createClient, reservoirChains } from "@reservoir0x/reservoir-sdk";
import bn from "bignumber.js";
import { ERC721Abi } from "../abis";
import { useAddressTokenIds } from "../hooks";
import { getNft, listEventsByNft, OrderEventModel } from "../api/gen/opensea";
import { OPENSEA_API_KEY, OPENSEA_OPERATORS, RESERVOIR_API_KEY } from "../settings";
import { Chain, OpenSeaSDK } from "opensea-js";
import moment from "moment";
import { clientToSigner } from "../helpers";
import { Wallet } from "ethers";
import { formatAddress } from "../helpers/address";
import { Log } from "../hooks/useLogs";
import ContentLoader from "react-content-loader";

interface AddressDataTableProps {
    activeChainId: number;
    accounts: Account[];
    pubClientsMap: Map<number, PublicClient>;
    tokenAddress: string | undefined;
    activeSet: Set<string>;
    toggleActive: (account: string) => void;
    toggleAllActive: () => void;
    addLog: (message: string, type?: Log["type"]) => void;
    balances: number[];
    tokenBalances: number[];
    approvalsOs: boolean[];
    approvalsBlur: boolean[];
    updateAccountsMeta: () => Promise<void>;
    isLoadingBalances: boolean;
}

const AddressDataTable: FC<AddressDataTableProps> = ({ activeChainId, accounts, pubClientsMap, tokenAddress, activeSet, toggleActive, toggleAllActive, addLog, tokenBalances, balances, updateAccountsMeta, approvalsOs, isLoadingBalances }) => {

    // const [shouldApproveOpenseaSet, setShouldApproveOpenseaSet] = useState<Set<string>>(new Set());
    // const [shouldApproveBlurSet, setShouldApproveBlurSet] = useState<Set<string>>(new Set());

    // const toggleAllShouldApproveOpensea = () => {
    //     let result = new Set<string>();

    //     if(shouldApproveOpenseaSet.size !== accounts.length) {
    //         result = new Set(accounts.map(accounts => accounts.address.toLowerCase()));
    //     }

    //     setShouldApproveOpenseaSet(result);
    // }

    // const toggleShouldApproveOpensea = (address: string) => {
    //     const result = new Set<string>(shouldApproveOpenseaSet);

    //     if(result.has(address.toLowerCase())) {
    //         result.delete(address.toLowerCase());
    //     } else {
    //         result.add(address.toLowerCase());
    //     }

    //     setShouldApproveOpenseaSet(result);
    // }

    // const toggleAllShouldApproveBlur = () => {
    //     let result = new Set<string>();

    //     if(shouldApproveBlurSet.size !== accounts.length) {
    //         result = new Set(accounts.map(accounts => accounts.address.toLowerCase()));
    //     }

    //     setShouldApproveBlurSet(result);
    // }

    // const toggleShouldApproveBlur = (address: string) => {
    //     const result = new Set<string>(shouldApproveBlurSet);

    //     if(result.has(address.toLowerCase())) {
    //         result.delete(address.toLowerCase());
    //     } else {
    //         result.add(address.toLowerCase());
    //     }

    //     setShouldApproveBlurSet(result);
    // }

    const toggleSet = (address: string) => {
        toggleActive(address.toLowerCase());
    }

    const onInitApprove = async () => {
        const pubClient = pubClientsMap.get(activeChainId);

        addLog("Started approvals");

        if(!pubClient) {
            return;
        }

        for(const value of activeSet.values()) {
            const account = accounts.find(account => account.address.toLowerCase() === value);

            if(account) {
                const client = account.walletClients.get(activeChainId);

                if(!client) {
                    console.error("No wallet client found for account:", account.address, " and chain id: ", activeChainId);

                    return;
                }

                // if(shouldApproveOpenseaSet.has(account.address.toLowerCase())) {
                    // @ts-ignore
                    const isApprovedAlready = await pubClient.readContract({
                        account: client.account,
                        address: tokenAddress as `0x${string}`,
                        abi: ERC721Abi,
                        functionName: "isApprovedForAll",
                        args: [OPENSEA_OPERATORS[activeChainId]],
                    })
                        .then((res: any) => res as boolean)
                        .catch((err: Error) => {
                            addLog(`Failed to check approval for ${account.address}. Error: ${err.message}`, "error");
                            return false;
                        });

                    if(isApprovedAlready) {
                        continue;
                    }

                    // @ts-ignore
                    const { request } = await pubClient.simulateContract({
                        account: client.account,
                        chain: client.chain,
                        address: tokenAddress as `0x${string}`,
                        abi: ERC721Abi,
                        functionName: "setApprovalForAll",
                        args: [OPENSEA_OPERATORS[activeChainId], true],
                    });

                    // @ts-ignore
                    const tx = await client.writeContract(request)
                    
                    // @ts-ignore
                    await pubClient.waitForTransactionReceipt({
                        hash: tx,
                    }).then((res: any) => {
                        const decimals = new bn(10).pow(18);
                        const gasFormatted = new bn(res.gasUsed.toString()).div(decimals).toNumber().toFixed(6)

                        addLog(`Approved opensea for ${account.address}. Gas used: ${gasFormatted} ETH`, "success");
                        updateAccountsMeta();
                    }).catch((err: any) => {
                        addLog(`Failed to approve opensea for ${account.address}. Error: ${err.message}`, "error");
                    })
                // }

                // if(shouldApproveBlurSet.has(account.address.toLowerCase())) {
                //     // @ts-ignore
                //     const { request } = await pubClient.simulateContract({
                //         account: client.account,
                //         chain: client.chain,
                //         address: tokenAddress as `0x${string}`,
                //         abi: ERC721Abi,
                //         functionName: "setApprovalForAll",
                //         args: [BLUR_OPERATORS[activeChainId], true],
                //     });

                //     // @ts-ignore
                //     const tx = await client.writeContract(request)
                    
                //     // @ts-ignore
                //     await pubClient.waitForTransactionReceipt({
                //         hash: tx,
                //     }).then((res: any) => {
                //         const decimals = new bn(10).pow(18);
                //         const gasFormatted = new bn(res.gasUsed.toString()).div(decimals).toNumber().toFixed(6)

                //         addLog(`Approved opensea for ${account.address}. Gas used: ${gasFormatted} ETH`, "success");
                //         updateAccountsMeta();
                //     }).catch((err: any) => {
                //         addLog(`Failed to approve opensea for ${account.address}. Error: ${err.message}`, "error");
                //     })
                // }
            }
        }
    }

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Balance ETH</TableCell>
                        <TableCell>Balance NFT</TableCell>
                        <TableCell>Opensea Approved</TableCell>
                        {/* <TableCell>Blur Approved</TableCell> */}
                        {/* <TableCell sx={{ width: "120px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                Approve on opensea
                                <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={shouldApproveOpenseaSet.size === accounts.length} onClick={toggleAllShouldApproveOpensea} />
                            </Box>
                        </TableCell> */}
                        {/* <TableCell sx={{ width: "120px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                Approve on blur
                                <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={shouldApproveBlurSet.size === accounts.length} onClick={toggleAllShouldApproveBlur} />
                            </Box>
                        </TableCell> */}
                        <TableCell>
                            <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={activeSet.size === accounts.length} onClick={toggleAllActive} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        accounts.map((account, accountIndex) => (
                            <TableRow key={account.address}>
                                <TableCell>{accountIndex + 1}</TableCell>
                                <TableCell>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`https://etherscan.io/address/${account.address}`}
                                    >
                                        {account.address}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {
                                        isLoadingBalances ? (
                                            <ContentLoader viewBox="0 0 50 16">
                                                <rect x="0" y="0" rx="5" ry="5" width="50" height="16"  />
                                            </ContentLoader>
                                        ) : (
                                            balances[accountIndex] ?? 0
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        isLoadingBalances ? (
                                            <ContentLoader viewBox="0 0 50 16">
                                                <rect x="0" y="0" rx="5" ry="5" width="50" height="16"  />
                                            </ContentLoader>
                                        ) : (
                                            tokenBalances[accountIndex] ?? 0
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={approvalsOs[accountIndex] ?? false} />
                                </TableCell>
                                {/* <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={approvalsBlur[accountIndex] ?? false} />
                                </TableCell> */}
                                {/* <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={shouldApproveOpenseaSet.has(account.address.toLowerCase())} onClick={() => toggleShouldApproveOpensea(account.address)} />
                                </TableCell> */}
                                {/* <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={shouldApproveBlurSet.has(account.address.toLowerCase())} onChange={() => toggleShouldApproveBlur(account.address)} />
                                </TableCell> */}
                                <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={activeSet.has(account.address.toLowerCase())} onChange={() => toggleSet(account.address)} />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Button onClick={onInitApprove} disabled={activeSet.size === 0} variant="contained">Approve for listing</Button>
            </Box>
        </Box>
    )
}

export type AdvancedTokenInfo = {
    id: string;
    address: string;
    tokenId: number;
    rarity: string | number;
    name: string;
    imgUrl: string;
    priceOpensea: string;
    priceBlur: string;
    expiresIn: string;
};

interface ListingViewProps {
    activeChainId: number;
    accounts: Account[];
    pubClientsMap: Map<number, PublicClient>;
    addLog: (message: string, type?: Log["type"]) => void;
    tokenAddress: string | undefined;
    setTokenAddress: (val: string | undefined) => void;
    tokenTempAddress: string | undefined;
    setTokenTempAddress: (val: string | undefined) => void;
    balances: number[];
    tokenBalances: number[];
    tokensInfo: AdvancedTokenInfo[];
    setTokensInfo: (tokensInfo: AdvancedTokenInfo[]) => void;
    approvalsOs: boolean[];
    approvalsBlur: boolean[];
    updateAccountsMeta: () => Promise<void>;
    selectedAccountsSet: Set<string>;
    setSelectedAccountsSet: (val: Set<string>) => void;
    selectedTokenInfosSet: Set<string>;
    setSelectedTokenInfosSet: (val: Set<string>) => void;
    allPricesValue: string | undefined;
    setAllPricesValue: (val: string | undefined) => void;
    tokenPriceInputs: Record<string, string>;
    setTokenPriceInputs: (val: Record<string, string>) => void;
    checksOpensea: Set<string>;
    setChecksOpensea: (val: Set<string>) => void;
    checksBlur: Set<string>;
    setChecksBlur: (val: Set<string>) => void;
    infoDurations: Record<string, number>;
    setInfoDurations: (val: Record<string, number>) => void;
    isLoadingBalances: boolean;
    tokenName: string | undefined;
    setTokenName: (val: string) => void;
}

type TimeDuration = [number, "day" | "month" | "hour"];

const Durations = [
    {
        value: [1, "day"] as TimeDuration,
        label: "1 day",
    },
    {
        value: [7, "day"] as TimeDuration,
        label: "7 days",
    },
    {
        value: [1, "month"] as TimeDuration,
        label: "1 month",
    },
];

export const ListingView: FC<ListingViewProps> = ({
    activeChainId,
    accounts,
    pubClientsMap,
    addLog,
    tokenAddress,
    setTokenAddress,
    tokenTempAddress,
    setTokenTempAddress,
    balances,
    tokenBalances,
    tokensInfo,
    setTokensInfo,
    approvalsBlur,
    approvalsOs,
    updateAccountsMeta,
    selectedAccountsSet,
    setSelectedAccountsSet,
    selectedTokenInfosSet,
    setSelectedTokenInfosSet,
    allPricesValue,
    setAllPricesValue,
    tokenPriceInputs,
    setTokenPriceInputs,
    checksOpensea,
    setChecksOpensea,
    checksBlur,
    setChecksBlur,
    infoDurations,
    setInfoDurations,
    isLoadingBalances,
    tokenName,
    setTokenName
}) => {
    const getAddressTokenIds = useAddressTokenIds(activeChainId);
    const [isLoadingTokensMeta, setIsLoadingTokensMeta] = useState<boolean>(false);
    // const [tokenAddress, setTokenAddress] = useState<string>();

    const selectAllDurations = useCallback((durationIndex: number) => {
        const durations: Record<string, number> = { ...infoDurations };

        for(const tokenInfo of tokensInfo) {
            durations[tokenInfo.id] = durationIndex;
        }

        setInfoDurations(durations);
    }, [infoDurations, setInfoDurations, tokensInfo]);

    const toggleAllAccounts = useCallback(() => {
        let result = new Set<string>();

        if(selectedAccountsSet.size !== accounts.length) {
            result = new Set<string>(accounts.map(account => account.address.toLowerCase()));
        }

        setSelectedAccountsSet(result);
    }, [accounts, selectedAccountsSet.size, setSelectedAccountsSet]);

    const toggleAccount = useCallback((account: string) => {
        const result = new Set<string>(selectedAccountsSet);

        if(result.has(account)) {
            result.delete(account);
        } else {
            result.add(account);
        }

        setSelectedAccountsSet(result);
    }, [selectedAccountsSet, setSelectedAccountsSet]);

    const toggleAllCheckOpensea = useCallback(() => {
        let result = new Set<string>();

        if(checksOpensea.size !== tokensInfo.length) {
            result = new Set<string>(tokensInfo.map(info => info.id));
        }

        setChecksOpensea(result);
    }, [checksOpensea.size, setChecksOpensea, tokensInfo]);

    // const toggleAllCheckBlur = useCallback(() => {
    //     let result = new Set<string>();

    //     if(checksBlur.size !== tokensInfo.length) {
    //         result = new Set<string>(tokensInfo.map(info => info.id));
    //     }

    //     setChecksBlur(result);
    // }, [checksBlur.size, setChecksBlur, tokensInfo]);

    const checkOpensea = useCallback((info: AdvancedTokenInfo) => {
        const result = new Set<string>(checksOpensea);

        if(result.has(info.id)) {
            result.delete(info.id);
        } else {
            result.add(info.id);
        }

        setChecksOpensea(result);
    }, [checksOpensea, setChecksOpensea]);

    // const checkBlur = useCallback((info: AdvancedTokenInfo) => {
    //     const result = new Set<string>(checksBlur);

    //     if(result.has(info.id)) {
    //         result.delete(info.id);
    //     } else {
    //         result.add(info.id);
    //     }

    //     setChecksBlur(result);
    // }, [checksBlur, setChecksBlur]);

    const setTokenPriceInput = useCallback((info: AdvancedTokenInfo, val: string) => {
        const result = {...tokenPriceInputs};

        result[info.id] = val;

        setTokenPriceInputs(result);
    }, [setTokenPriceInputs, tokenPriceInputs]);

    const setAllTokenInputs = useCallback((val: string) => {
        const newPrices = tokensInfo.reduce((acc, info) => {
            acc[info.id] = val;

            return acc;
        },{} as Record<string, string>);

        setTokenPriceInputs(newPrices);
    }, [setTokenPriceInputs, tokensInfo]);

    const setInfoDuration = useCallback((info: AdvancedTokenInfo, val: number) => {
        const result = {...infoDurations};

        result[info.id] = val;

        setInfoDurations(result);
    }, [infoDurations, setInfoDurations]);

    const toggleAllSelectedTokenInfo = () => {
        const selected = new Set<string>(tokensInfo.map((info) => info.id));

        if(selectedTokenInfosSet.size === tokensInfo.length) {
            selected.clear();
        }

        setSelectedTokenInfosSet(selected);
    }

    const toggleSelectedTokenInfo = (info: AdvancedTokenInfo) => {
        const selected = new Set<string>(selectedTokenInfosSet);

        const key = info.id;

        if(selected.has(key)) {
            selected.delete(key);
        } else {
            selected.add(key);
        }

        setSelectedTokenInfosSet(selected);
    }

    useEffect(() => {

    }, []);

    const onApplyAddress = () => {
        if(tokenAddress?.toLowerCase() !== tokenTempAddress?.toLowerCase()) {
            setTokenAddress(tokenTempAddress);
        }

        updateAccountsMeta();
    }

    const updateTokenInfos = useCallback(async () => {
        if(tokenAddress) {
            if(tokenAddress.match(/0x[a-fA-F0-9]{40}$/)) {
              const client = pubClientsMap.get(activeChainId);
      
              if(!client) {
                addLog(`Failed to update token name, no client exists for: ${activeChainId}`, "error");
                return;
              }
      
              // @ts-ignore
              client.readContract({
                address: tokenAddress as `0x${string}`,
                abi: ERC721Abi,
                functionName: "name",
              })
                .then((res: any) => setTokenName(res as string))
                .catch((err: Error) => {
                  console.error(err);
                  addLog(`Failed to update token name ${err.message}`);
                })
            } else {
              setTokenName("Wrong contract");
            }
        }

        const client = pubClientsMap.get(activeChainId);
        setIsLoadingTokensMeta(true);

        if(!client) {
            setIsLoadingTokensMeta(false);
            return;
        }

        if(!tokenAddress) {
            setIsLoadingTokensMeta(false);
            return;
        }

        let result = [];

        for(const account of accounts) {
            if(!selectedAccountsSet.has(account.address.toLowerCase())) {
                continue;
            }

            const tokenIds = await getAddressTokenIds(tokenAddress, account.address);

            for(const tokenId of tokenIds) {
                let chainName = "ethereum" as "ethereum" | "base";

                if(activeChainId === 8453) {
                    chainName = "base";
                }

                const meta = await getNft(chainName, tokenAddress, tokenId.toString(), {
                    headers: {
                        "x-api-key": OPENSEA_API_KEY,
                    }
                }).then((res) => res.data);

                const events = await listEventsByNft(chainName, tokenAddress, tokenId).then((res) => res.data.asset_events);
                
                const lastSale = events.find((e) => e.event_type === "order" && e.order_type === "listing") as OrderEventModel | undefined;
                let tokenSalePriceOs = "-";
                let tokenSalePriceBl = "-";
                let expiresIn = "-";

                if(lastSale) {
                    const decimals = new bn(10).pow(18);
                    tokenSalePriceOs = new bn(lastSale.payment?.quantity).div(decimals).toNumber().toFixed(6);

                    expiresIn = moment((lastSale.expiration_date ?? 0) * 1000).format("YYYY-MM-DD HH:mm:ss");
                }

                const tokenInfo: AdvancedTokenInfo = {
                    id: `${account.address}/${tokenId}`,
                    tokenId: Number(tokenId),
                    rarity: meta.nft.rarity?.rank ?? "-",
                    address: account.address,
                    name: meta.nft.name,
                    imgUrl: meta.nft.image_url ?? "",
                    priceOpensea: tokenSalePriceOs,
                    priceBlur: tokenSalePriceBl,
                    expiresIn
                }

                result.push(tokenInfo);
            }
        }

        setIsLoadingTokensMeta(false);
        setTokensInfo(result);
    }, [accounts, activeChainId, addLog, getAddressTokenIds, pubClientsMap, selectedAccountsSet, setTokenName, setTokensInfo, tokenAddress]);

    const listTokens = async () => {
        const pubClient = pubClientsMap.get(activeChainId);
        addLog(`Listing started`);

        if(!tokenAddress) {
            return;
        }

        if(!pubClient) {
            return;
        }

        for(let infoIndex = 0; infoIndex < tokensInfo.length; infoIndex++) {
            const info = tokensInfo[infoIndex];
            const account = accounts.find((account) => account.address.toLowerCase() === info.address.toLowerCase());

            if(!account) {
                console.error("No account found for address" + info.address.toLowerCase());
                addLog(`No account found for address ${info.address}`, "error");

                continue;
            }

            const walClient = account.walletClients.get(activeChainId);

            if(!walClient) {
                console.error("No wallet client found for account" + info.address);
                addLog(`No wallet client found for account ${info.address}`, "error");

                continue;
            }

            if(selectedTokenInfosSet.has(info.id)) {
                const priceNum = tokenPriceInputs[info.id] ? Number(tokenPriceInputs[info.id]) : -1;
                const durationIndex = infoDurations[info.id] ?? null;

                if(isNaN(priceNum) || priceNum < 0 || durationIndex === null || durationIndex < 0) {
                    console.log("Price num:", priceNum);
                    console.log("Durations:", infoDurations);
                    console.log("Duration index:", durationIndex);
                    console.error("Not all fields supplied");
                    addLog(`Not all fields supplied for account ${info.address} listing`, "error");
                    return;
                }

                const duration = Durations[durationIndex];

                if(!duration) {
                    console.error("No duration found for index:", durationIndex);
                    addLog(`Duration incorrect for account ${info.address} listing`, "error");

                    continue;
                }

                const expiresInDate = Math.floor(moment().add(duration.value[0], duration.value[1]).toDate().getTime() / 1000);

                // @ts-ignore
                const signer = clientToSigner(walClient);

                if(checksOpensea.has(info.id)) {
                    const wallet = new Wallet(account.pk, signer.provider);
                    const openseaSdk = new OpenSeaSDK(wallet, {
                        chain: Chain.Mainnet,
                        apiKey: OPENSEA_API_KEY,
                    });
    
                    await openseaSdk.createListing({
                        asset: {
                            tokenId: info.tokenId.toString(),
                            tokenAddress,
                        },
                        accountAddress: account.address,
                        startAmount: priceNum,
                        expirationTime: expiresInDate,
                    }).then((res: any) => {
                        addLog(`Opensea: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`, "success");
                        console.log("My order:", res);
                    }).catch((err: any) => {
                        console.error("Failed to create listing", err);
                        addLog(`Opensea: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`, "error");
                    });
                }

                if(checksBlur.has(info.id)) {                    
                    const reservoirSdk = createClient({
                        chains: [{
                            ...reservoirChains.mainnet,
                            active: true,
                        }],
                        apiKey: RESERVOIR_API_KEY,
                        source: "blur.io",
                    });

                    const decimals = new bn(10).pow(18);
                    const price = new bn(priceNum).times(decimals).toFixed();

                    const resWallet = adaptViemWallet(walClient);

                    // @ts-ignore
                    await reservoirSdk.actions.listToken({
                        wallet: resWallet,
                        listings: [
                            {
                                orderKind: "blur",
                                orderbook: "blur",
                                token: tokenAddress.toLowerCase() + ":" + info.tokenId.toString(),
                                weiPrice: price,
                                expirationTime: expiresInDate.toString(),
                            }
                        ]
                    }).then((res: any) => {
                        addLog(`Blur: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`, "success");
                        console.log("My blur listing:", res);
                    }).catch((err: Error) => {
                        addLog(`Blur: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`, "error");
                        console.error("Blur listing failed", err);
                    });
                }
            }   
        }

        addLog("Listing process finished");

        await updateTokenInfos();
    }

    const totalTokenBalance = useMemo(() => {
        return tokenBalances.reduce((acc, balance) => acc + balance, 0);
    }, [tokenBalances]);

    return (
        <Box m={2}>
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box> Contract address: </Box>
                <Box width={400}>
                    <TextField
                        fullWidth
                        value={tokenTempAddress}
                        onChange={(e) => setTokenTempAddress(e.target.value)}
                        variant="standard"
                        placeholder="0x00000000000000000000000000000000"
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Button variant="outlined" onClick={onApplyAddress}>Check token</Button>
                    <Button onClick={updateTokenInfos} disabled={selectedAccountsSet.size === 0} variant="outlined">Update token list</Button>
                    {/* <Button variant="contained" onClick={() => {
                        updateAccountsMeta();
                    }}>Update balances</Button> */}
                </Box>
            </Box>
            <Box width={1000}>
                <AddressDataTable
                    tokenAddress={tokenAddress}
                    pubClientsMap={pubClientsMap}
                    accounts={accounts}
                    activeChainId={activeChainId}
                    activeSet={selectedAccountsSet}
                    toggleActive={toggleAccount}
                    toggleAllActive={toggleAllAccounts}
                    addLog={addLog}
                    balances={balances}
                    tokenBalances={tokenBalances}
                    approvalsOs={approvalsOs}
                    approvalsBlur={approvalsBlur}
                    updateAccountsMeta={updateAccountsMeta}
                    isLoadingBalances={isLoadingBalances}
                />
            </Box>
            <Box mt={2} width={1200}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant="h5" color="black">{tokenName}</Typography>
                    <Typography variant="body1" color="black">(Total nfts owned: {totalTokenBalance})</Typography>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>PNG</TableCell>
                            <TableCell>Token Id</TableCell>
                            <TableCell>Rarity</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price OS</TableCell>
                            {/* <TableCell>Price BL</TableCell> */}
                            <TableCell>Expires at</TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    Duration
                                    <Select size="small" onChange={(e) => selectAllDurations(e.target.value as number)}>
                                        {
                                            Durations.map((duration, durationIndex) => (
                                                <MenuItem key={duration.label + durationIndex} value={durationIndex}>{duration.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                    <span>
                                        Price
                                    </span>
                                    <TextField
                                        type="number"
                                        value={allPricesValue}
                                        onChange={(e) => {
                                            setAllPricesValue(e.target.value);
                                            setAllTokenInputs(e.target.value);
                                        }}
                                        placeholder=""
                                        size="small"
                                        sx={{
                                            width: "120px"
                                        }}    
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    Opensea
                                    <Checkbox
                                        checked={checksOpensea.size === tokensInfo.length}
                                        onChange={() => toggleAllCheckOpensea()}
                                        inputProps={{'aria-label': 'controlled' }}
                                    />
                                </Box>
                            </TableCell>
                            {/* <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    Blur
                                    <Checkbox
                                        checked={checksBlur.size === tokensInfo.length}
                                        onChange={() => toggleAllCheckBlur()}
                                        inputProps={{'aria-label': 'controlled' }}
                                    />
                                </Box>
                            </TableCell> */}
                            <TableCell>
                                <Checkbox
                                    checked={selectedTokenInfosSet.size === tokensInfo.length}
                                    onChange={() => toggleAllSelectedTokenInfo()}
                                    inputProps={{'aria-label': 'controlled' }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !isLoadingTokensMeta && tokensInfo.map((info, infoIndex) => (
                                <TableRow key={`${info.address}_${info.tokenId}`}>
                                    <TableCell>{infoIndex + 1}</TableCell>
                                    <TableCell>{formatAddress(info.address)}</TableCell>
                                    <TableCell>
                                        <img
                                            src={info.imgUrl}
                                            width="64px"
                                            height="64px"
                                            alt="token"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={`https://opensea.io/assets/ethereum/${tokenAddress}/${info.tokenId}`}
                                        >
                                            {info.tokenId}
                                        </a>
                                    </TableCell>
                                    <TableCell>{info.rarity}</TableCell>
                                    <TableCell>{info.name}</TableCell>
                                    <TableCell>{info.priceOpensea}</TableCell>
                                    {/* <TableCell>{info.priceBlur}</TableCell> */}
                                    <TableCell>{info.expiresIn}</TableCell>
                                    <TableCell>
                                        <Select displayEmpty inputProps={{ 'aria-label': "controlled" }} size="small" onChange={(e) => setInfoDuration(info, e.target.value as number)} value={infoDurations[info.id] ?? ''}>
                                            {
                                                Durations.map((duration, durationIndex) => (
                                                    <MenuItem key={duration.label + info.id} value={durationIndex}>{duration.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0.0000"
                                            value={tokenPriceInputs[info.id]}
                                            onChange={(e) => setTokenPriceInput(info, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={checksOpensea.has(info.id) ?? false}
                                            onChange={(e, val) => checkOpensea(info)}
                                            inputProps={{  'aria-label': 'controlled' }}
                                        />
                                    </TableCell>
                                    {/* <TableCell>
                                        <Checkbox
                                            checked={checksBlur.has(info.id)}
                                            onChange={(e, val) => checkBlur(info)}
                                            inputProps={{  'aria-label': 'controlled' }}
                                        />
                                    </TableCell> */}
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedTokenInfosSet.has(info.id)}
                                            onChange={() => toggleSelectedTokenInfo(info)}
                                            inputProps={{  'aria-label': 'controlled' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    isLoadingTokensMeta && (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                            <CircularProgress />
                        </Box>
                    )
                }
            </Box>
            {
                !isLoadingTokensMeta && (
                    <Box mt={2}>
                        <Button onClick={listTokens} variant="contained">List</Button>
                    </Box>
                )
            }
        </Box>
    )
}