import { useEffect, useState } from "react"
import { PublicClient } from "viem";
import { SUPPORTED_CHAIN_IDS } from "../settings";
import { getPublicClient } from "../helpers";

export const usePublicClientsList = () => {
    const [clientsMap, setClientsMap] = useState<Map<number, PublicClient>>(new Map());

    useEffect(() => {
        const clients = new Map<number, PublicClient>();

        for(const chainId of SUPPORTED_CHAIN_IDS) {
            const client = getPublicClient(chainId);

            if(client) {
                clients.set(chainId, client as PublicClient);
            }
        }

        setClientsMap(clients);
    }, []);

    return clientsMap;
}