import { useMemo } from "react"
import { Box, Button, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import type { Account } from "../hooks/useWalletList";
import { SUPPORTED_CHAIN_IDS } from "../settings";
import * as chains_ from "viem/chains";
import { Log } from "../hooks/useLogs";

const chains = Object.values(chains_);

interface SettingsViewProps {
    activeChainId: number;
    setActiveChainId: (chainId: number) => void;
    walletCount: number | undefined;
    setWalletCount: (count: number) => void;
    accounts: Account[],
    createAccounts: (mnemonic: string, count: number, callback: Function) => void;
    addLog: (message: string, type?: Log["type"]) => void;
    seedPhrase: string | undefined;
    setSeedPhrase: (phrase: string) => void;
}

export const SettingsView = ({ accounts, activeChainId, setActiveChainId, createAccounts, walletCount, setWalletCount, addLog, seedPhrase, setSeedPhrase }: SettingsViewProps) => {

    const chainsSelectOpts = useMemo(() => {
        const result: any[] = [];

        for(const chainId of SUPPORTED_CHAIN_IDS) {
            const chain = chains.find((chain) => chain.id === chainId);

            if(!chain) {
                continue;
            }

            result.push({
                label: chain.name,
                value: chainId,
            })
        }

        return result;
    }, []);

    const onSetChainId = (event: SelectChangeEvent<number>) => {
        setActiveChainId(event.target.value as number);
        addLog(`Using different chain id ${event.target.value}`);
    };

    return (
        <Box mt={2}>
            <Box width={200}>
                <InputLabel id="select-chain-settings-label">Select chain</InputLabel>
                <Select
                    value={activeChainId}
                    onChange={onSetChainId}
                    labelId="select-chain-settings-label"
                    fullWidth
                >
                    {
                        chainsSelectOpts.map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))
                    }
                </Select>
            </Box>
            <Box mt={2} width={400}>
                <InputLabel id="select-chain-settings-label">Seed phrase</InputLabel>
                <TextField
                    fullWidth
                    value={seedPhrase}
                    onChange={(e) => setSeedPhrase(e.target.value)}
                    multiline
                    maxRows={20}
                    variant="filled"
                />
            </Box>
            <Box mt={2} width={200}>
                <InputLabel id="select-chain-settings-label">Num of addresses</InputLabel>
                <TextField
                    fullWidth
                    value={walletCount}
                    onChange={(e) => !isNaN(Number(e.target.value)) && setWalletCount(Number(e.target.value))}
                />
            </Box>
            <Box mt={2}>
                <Button
                    onClick={() => {
                        if(!seedPhrase || !walletCount || walletCount === 0) {
                            addLog("Some of values are incorrect: seed phrase, wallet count", "error");
                            return;
                        }

                        createAccounts(seedPhrase, walletCount, () => {
                            addLog("Accounts imported", "success");
                        });
                    }}
                    variant="contained"
                    disabled={!seedPhrase || walletCount === 0}
                >
                    Import wallets
                </Button>
            </Box>
        </Box>
    )
}