export const SUPPORTED_CHAIN_IDS = [1, 8453];

export const OPENSEA_API_KEY = "07c1d9984882421094e4bdb0bd6efdf4";
export const RESERVOIR_API_KEY = "95b9ed4e-76cd-5019-a618-4871779ac6c1";

export const OPENSEA_OPERATORS: Record<number, string | undefined> = {
    1: "0x1e0049783f008a0085193e00003d00cd54003c71",
};

export const BLUR_OPERATORS: Record<number, string | undefined> = {
    1: "0x2f18F339620a63e43f0839Eeb18D7de1e1Be4DfB",
}

export const OPENSEA_DEPLOYERS: Record<number, string | undefined> = {
    1: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
};

export const OPENSEA_PROTOCOLS: Record<number, string | undefined> = {
    1: "0x0000000000000068F116a894984e2DB1123eB395",
}