import { useCallback } from "react";
import { usePublicClientsList } from "./usePublicClientsList"
import { listNftsByAccount } from "../api/gen/opensea";

export const useAddressTokenIds = (activeChainId: number) => {
    const clients = usePublicClientsList();

    const getTokenIds = useCallback(async (tokenAddress: string, address: string) => {
        const client = clients.get(activeChainId);

        if(!client) {
            console.error("No client exists for chain id:", activeChainId);
            return [];
        }

        // const balance = await client.readContract({
        //     address: tokenAddress as `0x${string}`,
        //     abi: ERC721Abi,
        //     functionName: "balanceOf",
        //     args: [address],
        // })
        //     .then((res) => Number(res))
        //     .catch((err) => {
        //         console.error("Error getting token balance:", err);
        //         return 0;
        //     });


        // const reqs = [];

        // for(let i = 0; i < balance; i++) {
        //     const req = client.readContract({
        //         address: tokenAddress as `0x${string}`,
        //         abi: ERC721Abi,
        //         functionName: "tokenOfOwnerByIndex",
        //         args: [address, i],
        //     })

        //     reqs.push(req);
        // }

        // const tokenIds = await Promise.all(reqs).catch((err) => {
        //     console.error("Error getting token ids", err);

        //     return [];
        // })

        let chainName = "ethereum" as "ethereum" | "base";

        if(activeChainId === 8453) {
            chainName = "base";
        }

        const tokenIds = await listNftsByAccount(chainName, address)
            .then((res) => res.data.nfts.filter((nft) => nft.contract.toLowerCase() === tokenAddress.toLowerCase())
            .map((nft) => nft.identifier));

        // const tokenIds = await client.readContract({
        //     address: tokenAddress as `0x${string}`,
        //     abi: ERC721Abi,
        //     functionName: "tokensOfOwner",
        //     args: [address],
        // })

        return tokenIds;
    }, [activeChainId, clients]);

    return getTokenIds;
}