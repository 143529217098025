import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { OPENSEA_API_KEY } from "../../settings";

export type AxiosClient = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;

let axiosInstance: AxiosInstance;

const setAxiosInstanceOpensea = (): void => {
    if(!axiosInstance) {
        axiosInstance = Axios.create({ baseURL: "https://api.opensea.io/", headers: { "x-api-key": OPENSEA_API_KEY } });
    }
  };

export const axiosClientOpensea: AxiosClient = (config, options) => {
    setAxiosInstanceOpensea();

    return axiosInstance.request({ ...config, ...options });
};