import { Account, Client, createPublicClient, createWalletClient, http, Transport, WalletClient } from "viem";
import { HDKey, hdKeyToAccount } from "viem/accounts";
import * as bip39 from 'bip39';
import * as chains_ from "viem/chains";
import { Buffer } from "buffer";
import { BrowserProvider, JsonRpcSigner } from "ethers";

window.Buffer = Buffer;

const chains = Object.values(chains_);

export const getPublicClient = (chainId: number, rpc?: string) => {
    const chain = chains.find((chain) => chain.id === chainId);

    if(!chain) {
        return null;
    }

    return createPublicClient({
        // @ts-ignore
        chain,
        transport: http(rpc),
    });
}

const mnemonicToUint8Array = (mnemonic: string): Uint8Array | null => {
    if(!bip39.validateMnemonic(mnemonic)) {
        console.log("Wrong mnemonic:", mnemonic);
        return null;
    }

    const seed = bip39.mnemonicToSeedSync(mnemonic)
    const uint8Array = new Uint8Array(seed);

    return uint8Array;
}

const createAccountFromBip39Mnemonic = (mnemonic: string, accountIndex: number) => {
    const mnemonicArray = mnemonicToUint8Array(mnemonic);

    if(!mnemonicArray) {
        return [null, null];
    }
    const key = HDKey.fromMasterSeed(mnemonicArray);
    const walletHdPath = `m/44'/60'/0'/0/${accountIndex}`;
    const childKey = key.derive(walletHdPath);

    const pk = "0x" + Buffer.from(childKey.privateKey!).toString("hex");

    const account = hdKeyToAccount(key, { addressIndex: accountIndex });

    return [account, pk];
}

export const getHDWalletClient = (hdMnemonic: string, accountIndex: number, chainId: number, rpc?: string): [WalletClient | null, string | null] => {
    const chain = chains.find((chain) => chain.id === chainId);

    if(!chain) {
        console.log("Unknown chain", chainId);
        return [null, null];
    }

    const data = createAccountFromBip39Mnemonic(hdMnemonic, accountIndex);
    const account = data[0];
    const pk = data[1];

    if(!account) {
        return [null, null];
    }

    const client = createWalletClient({
        // @ts-ignore
        account,
        // @ts-ignore
        chain,
        transport: http(rpc),
    });

    return [client, pk as string];
}

export function clientToSigner(client: Client<Transport, chains_.Chain, Account>) {
    const { account, chain, transport } = client
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new BrowserProvider(transport, network)
    const signer = new JsonRpcSigner(provider, account.address)
    return signer
  }