/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * OpenSea API
 * The API for OpenSea
 * OpenAPI spec version: 2.0.0
 */
import { axiosClientOpensea } from '../client/opensea';
export type GetOffersOrderDirection = typeof GetOffersOrderDirection[keyof typeof GetOffersOrderDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOffersOrderDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOffersOrderBy = typeof GetOffersOrderBy[keyof typeof GetOffersOrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOffersOrderBy = {
  created_date: 'created_date',
  eth_price: 'eth_price',
} as const;

export type GetOffersParams = {
/**
 * Filter results by the contract address for NFT(s).  
 NOTE: If used, token_ids or token_id is required.
 */
asset_contract_address?: string;
/**
 * Restricts results to only include orders that are bundles of NFTs. Default: false
 */
bundled?: boolean;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
cursor?: string;
/**
 * The number of orders to return. Must be between 1 and 50. Default: 20
 */
limit?: number;
/**
 * Filter to only include orders that were listed after the given timestamp. This is a Unix epoch timestamp in seconds.
 */
listed_after?: string;
/**
 * Filter to only include orders that were listed before the given timestamp. This is a Unix epoch timestamp in seconds.
 */
listed_before?: string;
/**
 * Filter results by the order maker's wallet address.
 */
maker?: string;
/**
 * The order in which to sort the results. Default: created_date 
 NOTE: If `eth_price` is used, `asset_contract_address` and `token_id` are required.
 */
order_by?: GetOffersOrderBy;
/**
 * The direction in which to sort the results. Default: desc
 */
order_direction?: GetOffersOrderDirection;
/**
 * Payment Token Address to filter results. This ensures all returned orders are listed in a single currency.
 */
payment_token_address?: string;
/**
 * Filter results by the order taker's wallet address.
 */
taker?: string;
/**
 * An array of token IDs to search for (e.g. ?token_ids=1&token_ids=209). This endpoint will return a list of orders with token_id matching any of the IDs in this array. 
 NOTE: If used, asset_contract_address is required.
 */
token_ids?: number;
};

export type GetListingsOrderDirection = typeof GetListingsOrderDirection[keyof typeof GetListingsOrderDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetListingsOrderDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetListingsOrderBy = typeof GetListingsOrderBy[keyof typeof GetListingsOrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetListingsOrderBy = {
  created_date: 'created_date',
  eth_price: 'eth_price',
} as const;

export type GetListingsParams = {
/**
 * Filter results by the contract address for NFT(s).  
 NOTE: If used, token_ids or token_id is required.
 */
asset_contract_address?: string;
/**
 * Restricts results to only include orders that are bundles of NFTs. Default: false
 */
bundled?: boolean;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
cursor?: string;
/**
 * The number of orders to return. Must be between 1 and 50. Default: 20
 */
limit?: number;
/**
 * Filter to only include orders that were listed after the given timestamp. This is a Unix epoch timestamp in seconds.
 */
listed_after?: string;
/**
 * Filter to only include orders that were listed before the given timestamp. This is a Unix epoch timestamp in seconds.
 */
listed_before?: string;
/**
 * Filter results by the order maker's wallet address.
 */
maker?: string;
/**
 * The order in which to sort the results. Default: created_date 
 NOTE: If `eth_price` is used, `asset_contract_address` and `token_id` are required.
 */
order_by?: GetListingsOrderBy;
/**
 * The direction in which to sort the results. Default: desc
 */
order_direction?: GetListingsOrderDirection;
/**
 * Payment Token Address to filter results. This ensures all returned orders are listed in a single currency.
 */
payment_token_address?: string;
/**
 * Filter results by the order taker's wallet address.
 */
taker?: string;
/**
 * An array of token IDs to search for (e.g. ?token_ids=1&token_ids=209). This endpoint will return a list of orders with token_id matching any of the IDs in this array. 
 NOTE: If used, asset_contract_address is required.
 */
token_ids?: number;
};

export type GetTraitOffersV2Params = {
/**
 * The value of the trait (e.g. `0.5`). This is only used for decimal-based numeric traits to ensure it is parsed correctly.
 */
float_value?: number;
/**
 * The value of the trait (e.g. `10`). This is only used for numeric traits to ensure it is parsed correctly.
 */
int_value?: number;
/**
 * The name of the trait (e.g. 'Background')
 */
type?: string;
/**
 * The value of the trait (e.g. 'Red')
 */
value?: string;
};

export type GetAllOffersOnCollectionV2Params = {
/**
 * The number of offers to return. Must be between 1 and 100. Default: 100
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type GetAllListingsOnCollectionV2Params = {
/**
 * The number of listings to return. Must be between 1 and 100. Default: 100
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListEventsByCollectionEventTypeItem = typeof ListEventsByCollectionEventTypeItem[keyof typeof ListEventsByCollectionEventTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEventsByCollectionEventTypeItem = {
  all: 'all',
  cancel: 'cancel',
  order: 'order',
  redemption: 'redemption',
  sale: 'sale',
  transfer: 'transfer',
} as const;

export type ListEventsByCollectionParams = {
/**
 * Filter to only include events that occurred at or after the given timestamp. The Unix epoch timstamp must be in seconds
 */
after?: number;
/**
 * Filter to only include events that occurred before the given timestamp. The Unix epoch timstamp must be in seconds.
 */
before?: number;
/**
 * The type of event to filter by. If not provided, only sales will be returned.
 */
event_type?: ListEventsByCollectionEventTypeItem[];
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListEventsByNftEventTypeItem = typeof ListEventsByNftEventTypeItem[keyof typeof ListEventsByNftEventTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEventsByNftEventTypeItem = {
  all: 'all',
  cancel: 'cancel',
  order: 'order',
  redemption: 'redemption',
  sale: 'sale',
  transfer: 'transfer',
} as const;

export type ListEventsByNftParams = {
/**
 * Filter to only include events that occurred at or after the given timestamp. The Unix epoch timstamp must be in seconds
 */
after?: number;
/**
 * Filter to only include events that occurred before the given timestamp. The Unix epoch timstamp must be in seconds.
 */
before?: number;
/**
 * The type of event to filter by. If not provided, only sales will be returned.
 */
event_type?: ListEventsByNftEventTypeItem[];
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListEventsByAccountEventTypeItem = typeof ListEventsByAccountEventTypeItem[keyof typeof ListEventsByAccountEventTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEventsByAccountEventTypeItem = {
  all: 'all',
  cancel: 'cancel',
  order: 'order',
  redemption: 'redemption',
  sale: 'sale',
  transfer: 'transfer',
} as const;

export type ListEventsByAccountChain = typeof ListEventsByAccountChain[keyof typeof ListEventsByAccountChain];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEventsByAccountChain = {
  arbitrum: 'arbitrum',
  arbitrum_goerli: 'arbitrum_goerli',
  arbitrum_nova: 'arbitrum_nova',
  avalanche: 'avalanche',
  avalanche_fuji: 'avalanche_fuji',
  baobab: 'baobab',
  base: 'base',
  base_goerli: 'base_goerli',
  bsc: 'bsc',
  bsctestnet: 'bsctestnet',
  ethereum: 'ethereum',
  goerli: 'goerli',
  klaytn: 'klaytn',
  matic: 'matic',
  mumbai: 'mumbai',
  optimism: 'optimism',
  optimism_goerli: 'optimism_goerli',
  sepolia: 'sepolia',
  solana: 'solana',
  soldev: 'soldev',
  zora: 'zora',
  zora_testnet: 'zora_testnet',
} as const;

export type ListEventsByAccountParams = {
/**
 * Filter to only include events that occurred at or after the given timestamp. The Unix epoch timstamp must be in seconds
 */
after?: number;
/**
 * Filter to only include events that occurred before the given timestamp. The Unix epoch timstamp must be in seconds.
 */
before?: number;
/**
 * The blockchain on which to filter the results.
 */
chain?: ListEventsByAccountChain;
/**
 * The type of event to filter by. If not provided, only sales will be returned.
 */
event_type?: ListEventsByAccountEventTypeItem[];
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListCollectionsParams = {
/**
 * The blockchain on which to filter the results
 */
chain_identifier?: string;
/**
 * If true, will return hidden collections. Default: false
 */
include_hidden?: boolean;
/**
 * The number of collections to return. Must be between 1 and 100. Default: 100
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListNftsByCollectionParams = {
/**
 * The number of NFTs to return. Must be between 1 and 50. Default: 50
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListNftsByContractParams = {
/**
 * The number of NFTs to return. Must be between 1 and 50. Default: 50
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

export type ListNftsByAccountParams = {
/**
 * Unique string to identify a collection on OpenSea. This can be found by visiting the collection on the OpenSea website and noting the last path parameter.
 */
collection?: string;
/**
 * The number of NFTs to return. Must be between 1 and 50. Default: 50
 */
limit?: number;
/**
 * The cursor for the next page of results. This is returned from a previous request.
 */
next?: string;
};

/**
 * basic - Quantities are fixed. Used for fixed price listings and offers.
dutch - The quantity represents the starting price.
english - The quantity represents the minimum price.
criteria - The items that are accepted by this offer will be found in the criteria fields.
 */
export type CoreTypesOrderType = typeof CoreTypesOrderType[keyof typeof CoreTypesOrderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoreTypesOrderType = {
  basic: 'basic',
  dutch: 'dutch',
  english: 'english',
  criteria: 'criteria',
} as const;

/**
 * The type of order, which determines how it can be executed.
0 = Full Open - No partial fills, anyone can execute
1 = Partial Open - Partial fills supported, anyone can execute
2 = Full Restricted - No partial fills, only offerer or zone can check if it can be executed
3 = Partial Restricted - Partial fills supported, only offerer or zone can check if it can be executed
4 = Contract - Contract order type, for contract offerers that can dynamically generate orders. Introduced in Seaport v1.4 and currently unsupported
 */
export type CoreBlockchainEvmAbiModelsSeaportOrderType = typeof CoreBlockchainEvmAbiModelsSeaportOrderType[keyof typeof CoreBlockchainEvmAbiModelsSeaportOrderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoreBlockchainEvmAbiModelsSeaportOrderType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type TransferEventModelEventTypeEnum = typeof TransferEventModelEventTypeEnum[keyof typeof TransferEventModelEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferEventModelEventTypeEnum = {
  transfer: 'transfer',
} as const;

export interface TransferEventModel {
  /** The chain on which the transfer occurred */
  chain: ChainIdentifier;
  event_type?: TransferEventModelEventTypeEnum;
  /** Address of the sender */
  from_address: string;
  /** Number of assets transferred */
  quantity: number;
  /** Address of the recipient */
  to_address: string;
  /** Transaction hash for the transfer */
  transaction: string;
}

/**
 * Decoded Call Data.
 */
export type TransactionInputData = { [key: string]: unknown };

export interface Transaction {
  /** Numeric Chain Identifier. */
  chain: number;
  /** Seaport protocol contract method to use to fulfill the order. */
  function: string;
  /** Decoded Call Data. */
  input_data: TransactionInputData;
  /** Protocol contract address to use fto fulfill the order. */
  to: string;
  /** Wei value of the transaction. */
  value: number;
}

/**
 * The value of the trait (e.g. 'Red')
 */
export type TraitModelValue = number | number | string | string;

export interface TraitModel {
  display_type?: DisplayTypeField;
  /** Ceiling for possible numeric trait values */
  max_value: string;
  /** Deprecated Field */
  order?: number;
  /** Deprecated Field. Use Get Collection API instead. */
  trait_count?: number;
  /**
   * The name of the trait category (e.g. 'Background')
   * @maxLength 150
   */
  trait_type: string;
  /** The value of the trait (e.g. 'Red') */
  value: TraitModelValue;
}

export interface Trait {
  type: string;
  value: string;
}

export interface SocialMediaAccountModel {
  /** The social media platform, e.g. twitter or instagram */
  platform: string;
  /** The username for the social media platform */
  username: string;
}

export interface SimpleAccount {
  /** The unique blockchain identifier, address, of the account. */
  readonly address: string;
  readonly config: ConfigEnum;
  /** A placeholder image. For the actual profile image, call the Get Account endpoint. */
  readonly profile_img_url: string;
  /** @nullable */
  readonly user: number | null;
}

export interface SimpleFee {
  readonly account: SimpleAccount;
  basis_points: string;
}

export type SerializedOrderComponentsCounter = number | string;

export interface SerializedOrder {
  parameters: SerializedOrderComponents;
  /** The order maker's signature used to validate the order. */
  signature?: string;
}

/**
 * OpenSea Order Object
 */
export interface SignedSimpleOrderV2 {
  /** If true, the order maker has canceled the order which means it can no longer be filled. */
  cancelled: boolean;
  /** Date the order was closed */
  closing_date: string;
  /** Date the order was created */
  created_date: string;
  /**
   * A merkle root composed of the valid set of token identifiers for the order
   * @nullable
   */
  readonly criteria_proof: readonly string[] | null;
  /** Current price of the order */
  current_price: string;
  /** Timestamp representation of closing_date */
  readonly expiration_time: number;
  /** If true, the order has already been filled. */
  finalized: boolean;
  /** Timestamp representation of created_date */
  readonly listing_time: number;
  /** The unique blockchain identifier, address, of the wallet which is the order maker. */
  readonly maker: SimpleAccount;
  readonly maker_fees: readonly SimpleFee[];
  /** If true, the order is currently invalid and can not be filled. */
  readonly marked_invalid: boolean;
  /** An identifier for the order */
  order_hash: string;
  readonly order_type: OrderTypeEnum;
  /**
   * Exchange Contract Address. Typically the address of the Seaport contract.
   * @nullable
   */
  readonly protocol_address: string | null;
  readonly protocol_data: SerializedOrder;
  /** Deprecated Field */
  readonly relay_id: string;
  /** The remaining quantity of the order that has not been filled. This is useful for erc1155 orders. */
  remaining_quantity: number;
  /** The side of the order, either bid (offer) or ask(listing). */
  side: string;
  /** The unique blockchain identifier, address, of the wallet which is the order taker. */
  readonly taker: SimpleAccount;
  readonly taker_fees: readonly SimpleFee[];
}

export interface SerializedOfferItem {
  /** When endAmount differs from `startAmount`, the realized amount is calculated linearly based on the time elapsed since the order became active. */
  endAmount: string;
  /** The ERC721 or ERC1155 token identifier or, in the case of a criteria-based item type, a merkle root composed of the valid set of token identifiers for the item. This value will be ignored for Ether and ERC20 item types, and can optionally be zero for criteria-based item types to allow for any identifier. */
  identifierOrCriteria: string;
  itemType: ItemType;
  /** The amount of the token in question that will be required should the order be fulfilled. */
  startAmount: string;
  /** The item's token contract (with the null address used for native tokens) */
  token: string;
}

export interface SerializedConsiderationItem {
  /** When endAmount differs from `startAmount`, the realized amount is calculated linearly based on the time elapsed since the order became active. */
  endAmount: string;
  /** The ERC721 or ERC1155 token identifier or, in the case of a criteria-based item type, a merkle root composed of the valid set of token identifiers for the item. This value will be ignored for Ether and ERC20 item types, and can optionally be zero for criteria-based item types to allow for any identifier. */
  identifierOrCriteria: string;
  itemType: ItemType;
  /** The address which will receive the consideration item when the order is executed. */
  recipient: string;
  /** The amount of the token in question that will be required should the order be fulfilled. */
  startAmount: string;
  /** The item's token contract (with the null address used for native tokens) */
  token: string;
}

export interface SerializedOrderComponents {
  /** Indicates what conduit, if any, should be utilized as a source for token approvals when performing transfers. By default (i.e. when conduitKey is set to the zero hash), the offerer will grant transfer approvals to Seaport directly. 
To utilize OpenSea's conduit, use 0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000 */
  conduitKey: string;
  consideration: SerializedConsiderationItem[];
  counter: SerializedOrderComponentsCounter;
  /** The block timestamp at which the order expires */
  endTime: string;
  offer: SerializedOfferItem[];
  /** The address which supplies all the items in the offer. */
  offerer: string;
  orderType: CoreBlockchainEvmAbiModelsSeaportOrderType;
  /** an arbitrary source of entropy for the order */
  salt: string;
  /** The block timestamp at which the order becomes active */
  startTime: string;
  /** Size of the consideration array. */
  totalOriginalConsiderationItems?: number;
  /** Optional secondary account attached the order which can cancel orders. Additionally, when the `OrderType` is Restricted, the zone or the offerer are the only entities which can execute the order.
For open orders, use the zero address.
For restricted orders, use the signed zone address <SIGNED_ZONE_ADDRESS> */
  zone: string;
  /** A value that will be supplied to the zone when fulfilling restricted orders that the zone can utilize when making a determination on whether to authorize the order. Most often this value will be the zero hash 0x0000000000000000000000000000000000000000000000000000000000000000 */
  zoneHash: string;
}

export type SaleEventModelEventTypeEnum = typeof SaleEventModelEventTypeEnum[keyof typeof SaleEventModelEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleEventModelEventTypeEnum = {
  sale: 'sale',
} as const;

export interface SaleEventModel {
  /** The chain on which the order was fulfilled */
  chain: ChainIdentifier;
  /** The Posix timestamp at which the transaction which filled the order occurred */
  closing_date: number;
  event_type?: SaleEventModelEventTypeEnum;
  /** Maker of the order */
  maker: string;
  /** Order hash for the order which was fulfilled */
  order_hash: string;
  payment: EventPaymentModel;
  /** Exchange contract address which fulfilled the order */
  protocol_address: string;
  /** Number of assets transferred */
  quantity: number;
  /** Taker of the order */
  taker: string;
  /** Transaction hash for the order fulfillment */
  transaction: string;
}

/**
 * Status of the collection verification requests.
 */
export type SafelistRequestStatus = typeof SafelistRequestStatus[keyof typeof SafelistRequestStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SafelistRequestStatus = {
  not_requested: 'not_requested',
  requested: 'requested',
  approved: 'approved',
  verified: 'verified',
  disabled_top_trending: 'disabled_top_trending',
} as const;

export type RedemptionEventModelEventTypeEnum = typeof RedemptionEventModelEventTypeEnum[keyof typeof RedemptionEventModelEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RedemptionEventModelEventTypeEnum = {
  redemption: 'redemption',
} as const;

export type RedemptionEventModelAssetAnyOf = { [key: string]: unknown };

/**
 * The asset being redeemed.
 */
export type RedemptionEventModelAsset = NftModel | RedemptionEventModelAssetAnyOf;

export interface RedemptionEventModel {
  /** The asset being redeemed. */
  asset: RedemptionEventModelAsset;
  /** The chain on which the rededemption occurred */
  chain: ChainIdentifier;
  event_type?: RedemptionEventModelEventTypeEnum;
  /** Address of the sender */
  from_address: string;
  /** Number of assets redeemed */
  quantity: number;
  /** Address of the recipient */
  to_address: string;
  /** Transaction hash for the redemption */
  transaction: string;
}

/**
 * Rarity algorithm used. Currently, always 'openrarity'
 */
export type RarityStrategyId = typeof RarityStrategyId[keyof typeof RarityStrategyId];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RarityStrategyId = {
  openrarity: 'openrarity',
} as const;

export interface RankingFeatures {
  /** Deprecated Field. */
  unique_attribute_count?: number;
}

export interface RarityDataModel {
  /** Deprecated Field */
  calculated_at?: string;
  /** Deprecated Field */
  max_rank?: number;
  /** Rarity Rank of the NFT in the collection */
  rank: number;
  /** Deprecated Field */
  ranking_features?: RankingFeatures;
  /** Deprecated Field */
  score?: number;
  /** Deprecated Field */
  strategy_id?: RarityStrategyId;
  /** Deprecated Field */
  strategy_version?: string;
  /** Deprecated Field */
  total_supply?: number;
}

export interface PriceModel {
  currency: string;
  decimals: number;
  value: string;
}

export interface PostCriteriaOfferInput {
  /** Criteria for the collection or trait offer */
  criteria: Criteria;
  /** Exchange contract address. Must be one of ['0x00000000000000adc04c56bf30ac9d3c0aaf14dc'] */
  protocol_address: string;
  /** The signed order which will be submitted to Seaport */
  protocol_data: OrderInput;
}

export interface PartialParameters {
  /** One of the consideration items used when creating criteria offers. */
  consideration: SerializedConsiderationItem[];
  /** Optional secondary account attached the order which can cancel orders. Additionally, when the `OrderType` is Restricted, the zone or the offerer are the only entities which can execute the order.
For open orders, use the zero address.
For restricted orders, use the signed zone address 0x000000e7ec00e7b300774b00001314b8610022b8 */
  zone: string;
  /** A value that will be supplied to the zone when fulfilling restricted orders that the zone can utilize when making a determination on whether to authorize the order. Most often this value will be the zero hash 0x0000000000000000000000000000000000000000000000000000000000000000 */
  zoneHash: string;
}

export interface PaginatedOfferList {
  /** Cursor for the next page of results */
  next: string;
  offers: Offer[];
}

export interface PaginatedListingList {
  /** OpenSea Listings */
  listings: Listing[];
  /** Cursor for the next page of results */
  next: string;
}

export interface OwnerModel {
  /** The unique public blockchain identifier for the owner wallet */
  address: string;
  /** The number of tokens owned  */
  quantity: number;
}

/**
 * Deprecated Field.
 */
export type OrderV2TakerAssetBundle = {[key: string]: unknown};

/**
 * Deprecated Field.
 */
export type OrderV2MakerAssetBundle = {[key: string]: unknown};

/**
 * * `basic` - basic
* `dutch` - dutch
* `english` - english
* `criteria` - criteria
 */
export type OrderTypeEnum = typeof OrderTypeEnum[keyof typeof OrderTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTypeEnum = {
  basic: 'basic',
  dutch: 'dutch',
  english: 'english',
  criteria: 'criteria',
} as const;

/**
 * Models OrderV2 objects to serialize to a 'similar' schema to what we have with OrderV1s
 */
export interface OrderV2 {
  /** If true, the order maker has canceled the order which means it can no longer be filled. */
  cancelled: boolean;
  /** Date the order was closed */
  closing_date: string;
  /** Date the order was created */
  created_date: string;
  /**
   * A merkle root composed of the valid set of token identifiers for the order
   * @nullable
   */
  readonly criteria_proof: readonly string[] | null;
  /** Current price of the order */
  current_price: string;
  /** Timestamp representation of closing_date */
  readonly expiration_time: number;
  /** If true, the order has already been filled. */
  finalized: boolean;
  /** Timestamp representation of created_date */
  readonly listing_time: number;
  /** The unique blockchain identifier, address, of the wallet which is the order maker. */
  readonly maker: SimpleAccount;
  /** Deprecated Field. */
  readonly maker_asset_bundle: OrderV2MakerAssetBundle;
  readonly maker_fees: readonly SimpleFee[];
  /** If true, the order is currently invalid and can not be filled. */
  readonly marked_invalid: boolean;
  /** An identifier for the order */
  order_hash: string;
  readonly order_type: OrderTypeEnum;
  /**
   * Exchange Contract Address. Typically the address of the Seaport contract.
   * @nullable
   */
  readonly protocol_address: string | null;
  readonly protocol_data: SerializedOrder;
  /** Deprecated Field */
  readonly relay_id: string;
  /** The remaining quantity of the order that has not been filled. This is useful for erc1155 orders. */
  remaining_quantity: number;
  /** The side of the order, either bid (offer) or ask(listing). */
  side: string;
  /** The unique blockchain identifier, address, of the wallet which is the order taker. */
  readonly taker: SimpleAccount;
  /** Deprecated Field. */
  readonly taker_asset_bundle: OrderV2TakerAssetBundle;
  readonly taker_fees: readonly SimpleFee[];
}

/**
 * An enumeration.
 */
export type OrderType = typeof OrderType[keyof typeof OrderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderType = {
  listing: 'listing',
  auction: 'auction',
  item_offer: 'item_offer',
  collection_offer: 'collection_offer',
  trait_offer: 'trait_offer',
} as const;

export interface OrderInputComponents {
  /** Indicates what conduit, if any, should be utilized as a source for token approvals when performing transfers. By default (i.e. when conduitKey is set to the zero hash), the offerer will grant transfer approvals to Seaport directly. 
To utilize OpenSea's conduit, use 0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000 */
  conduitKey: string;
  /** Array of items which must be received by a recipient to fulfill the order. One of the consideration items must be the OpenSea marketplace fee. */
  consideration: ConsiderationItem[];
  /** Must match the current counter for the given offerer. If you are unsure of the current counter, it can be [read from the contract](https://etherscan.io/address/0x00000000000000adc04c56bf30ac9d3c0aaf14dc#readContract#F2) on etherscan. */
  counter: string;
  /** The block timestamp at which the order expires. */
  endTime: number;
  /** Array of items that may be transferred from the offerer's account. */
  offer: OfferItem[];
  /** The address which supplies all the items in the offer. */
  offerer: string;
  orderType: OrderType;
  /** an arbitrary source of entropy for the order */
  salt: string;
  /** The block timestamp at which the order becomes active */
  startTime: number;
  /** Size of the consideration array. */
  totalOriginalConsiderationItems?: number;
  /** Optional secondary account attached the order which can cancel orders. Additionally, when the `OrderType` is Restricted, the zone or the offerer are the only entities which can execute the order.
For open orders, use the zero address.
For restricted orders, use the signed zone address <SIGNED_ZONE_ADDRESS> */
  zone: string;
  /** A value that will be supplied to the zone when fulfilling restricted orders that the zone can utilize when making a determination on whether to authorize the order. Most often this value will be the zero hash 0x0000000000000000000000000000000000000000000000000000000000000000 */
  zoneHash: string;
}

export interface OrderInputWithProtocol {
  parameters: OrderInputComponents;
  /** Exchange contract address. Must be one of ['0x00000000000000adc04c56bf30ac9d3c0aaf14dc'] */
  protocol_address: string;
  /** Signature of the signed type data represented by the parameters field. */
  signature: string;
}

export interface OrderInput {
  parameters: OrderInputComponents;
  /** Signature of the signed type data represented by the parameters field. */
  signature: string;
}

export type OrderEventModelEventTypeEnum = typeof OrderEventModelEventTypeEnum[keyof typeof OrderEventModelEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderEventModelEventTypeEnum = {
  order: 'order',
} as const;

export type OrderEventModelCriteriaAnyOf = { [key: string]: unknown };

/**
 * For collection and trait offers, this object will contain the criteria needed to fulfill the offer.
 */
export type OrderEventModelCriteria = Criteria | OrderEventModelCriteriaAnyOf;

export type OrderEventModelAssetAnyOf = { [key: string]: unknown };

/**
 * The asset being listed or bid on. Empty object for collection or trait offers.
 */
export type OrderEventModelAsset = NftModel | OrderEventModelAssetAnyOf;

export interface OfferItem {
  /** When endAmount differs from `startAmount`, the realized amount is calculated linearly based on the time elapsed since the order became active. */
  endAmount: number;
  /** The ERC721 or ERC1155 token identifier or, in the case of a criteria-based item type, a merkle root composed of the valid set of token identifiers for the item. This value will be ignored for Ether and ERC20 item types, and can optionally be zero for criteria-based item types to allow for any identifier. */
  identifierOrCriteria: number;
  itemType: ItemType;
  /** The amount of the token in question that will be required should the order be fulfilled. */
  startAmount: number;
  /** The item's token contract (with the null address used for native tokens) */
  token: string;
}

export interface Offer {
  chain: ChainIdentifier;
  /** Criteria for collection or trait offers */
  criteria?: Criteria;
  /** Order hash */
  order_hash: string;
  /** Exchange contract address */
  protocol_address: string;
  /** The onchain order data. */
  protocol_data: SerializedOrder;
}

export interface OfferList {
  offers: Offer[];
}

export interface NftModel {
  /** Collection slug. A unique string to identify a collection on OpenSea */
  collection: string;
  /** The unique public blockchain identifier for the contract */
  contract: string;
  /** Deprecated Field */
  created_at?: CreatedAtEnum;
  /** Description of the NFT */
  description: string;
  /** The NFT's unique identifier within the smart contract (also referred to as token_id) */
  identifier: string;
  /** Link to the image associated with the NFT */
  image_url?: string;
  /** If the item is currently able to be bought or sold using OpenSea */
  is_disabled: boolean;
  /** If the item is currently classified as 'Not Safe for Work' by OpenSea */
  is_nsfw: boolean;
  /** Link to the offchain metadata store */
  metadata_url?: string;
  /** Name of the NFT */
  name: string;
  /** ERC standard of the token (erc721, erc1155) */
  token_standard: string;
  /** Last time that the NFT's metadata was updated by OpenSea */
  updated_at: string;
}

export interface Listing {
  /** Chain the listing is on. */
  chain: ChainIdentifier;
  /** Order hash */
  order_hash: string;
  price: BasicListingPrice;
  /** Exchange contract address */
  protocol_address: string;
  /** The onchain order data. */
  protocol_data: SerializedOrder;
  type: CoreTypesOrderType;
}

export interface ListNftsResponse {
  /** Cursor for the next page of results */
  next: string;
  nfts: NftModel[];
}

export type ListEventsResponseAssetEventsItem = CancelEventModel | OrderEventModel | SaleEventModel | TransferEventModel | RedemptionEventModel;

export interface ListEventsResponse {
  asset_events: ListEventsResponseAssetEventsItem[];
  /** Cursor for the next page of results */
  next: string;
}

export interface ListCollectionsResponse {
  collections: CollectionModel[];
  /** Cursor for the next page of results */
  next: string;
}

/**
 * 0 - Native - Ether (or other native token for the given chain)
1 - ERC20
2 - ERC721
3 - ERC1155
4 - ERC721 with criteria
5 - ERC1155 with criteria
 */
export type ItemType = typeof ItemType[keyof typeof ItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

/**
 * If the category type is STRING, the dict will contain each trait value and its count. Otherwise, the dict will contain the min and max value seen in the collection
 */
export type GetTraitResponseCounts = {[key: string]: {[key: string]: number}};

/**
 * List of trait categories, e.g. Background, in the collection and their type, e.g. string
 */
export type GetTraitResponseCategories = {[key: string]: CategoryType};

export interface GetTraitResponse {
  /** List of trait categories, e.g. Background, in the collection and their type, e.g. string */
  categories?: GetTraitResponseCategories;
  /** If the category type is STRING, the dict will contain each trait value and its count. Otherwise, the dict will contain the min and max value seen in the collection */
  counts?: GetTraitResponseCounts;
}

export type GetOrderResultValOrder = Listing | Offer;

export interface GetOrderResultVal {
  order: GetOrderResultValOrder;
}

export interface GetOfferResponse {
  /** The cursor for the next page of results. */
  next: string;
  orders: OrderV2[];
  /** The cursor for the previous page of results. */
  previous: string;
}

export interface GetNftResponse {
  nft: DetailedNftModel;
}

export interface GetListingsResponse {
  /** The cursor for the next page of results. */
  next: string;
  orders: OrderV2[];
  /** The cursor for the previous page of results. */
  previous: string;
}

export interface GetCollectionStatsResponse {
  /** The stats for each interval */
  intervals: CollectionStatsIntervalModel[];
  /** The aggregate stats over the collection's lifetime */
  total: CollectionStatsModel;
}

export interface FulfillmentInput {
  chain: string;
  /** Hash of the order to fulfill. */
  hash: string;
  /** Exchange contract address. Must be one of ['0x00000000000000adc04c56bf30ac9d3c0aaf14dc'] */
  protocol_address?: string;
}

export interface FulfillmentData {
  /** Array of Seaport Orders. */
  orders: SerializedOrder[];
  /** The name of the fulfillment method and associated call data. */
  transaction: Transaction;
}

export interface FulfillmentOutput {
  /** All the information, including signatures, needed to fulfill an order directly onchain. */
  fulfillment_data: FulfillmentData;
  /** Exchange contract address. Must be one of ['0x00000000000000adc04c56bf30ac9d3c0aaf14dc'] */
  protocol: string;
}

export interface FulfillerInput {
  address: string;
}

export interface GenerateListingFulfillmentInput {
  /** Fulfiller address. */
  fulfiller: FulfillerInput;
  /** Listing to get fullfillment data for. */
  listing: FulfillmentInput;
}

export interface EventPaymentModel {
  /** Returns the number of decimals the token uses - e.g. 8, means to divide the token amount by 100000000 to get its user representation. */
  decimals: number;
  /** Amount of tokens in the order */
  quantity: number;
  /** Returns the symbol of the token, e.g. ETH, WETH, USDC, etc */
  symbol: string;
  /** The contract address for the ERC20 token */
  token_address: string;
}

export interface OrderEventModel {
  /** The asset being listed or bid on. Empty object for collection or trait offers. */
  asset: OrderEventModelAsset;
  /** The chain on which the order was created */
  chain: ChainIdentifier;
  /** For collection and trait offers, this object will contain the criteria needed to fulfill the offer. */
  criteria: OrderEventModelCriteria;
  event_type?: OrderEventModelEventTypeEnum;
  /** The Posix timestamp at which the order will close. When no expiration date is set, this value will be 0. */
  expiration_date: number;
  /** Maker of the order */
  maker: string;
  /** Order hash for the newly created order */
  order_hash: string;
  order_type: OrderType;
  payment: EventPaymentModel;
  /** Exchange contract address for the order */
  protocol_address: string;
  /** Number of assets in the order */
  quantity: number;
  /** The Posix timestamp at which the order was created */
  start_date: number;
  /** Taker of the order. This will only be set for private listings. */
  taker: string;
}

/**
 * A field indicating how to display. None is used for string traits.
 */
export type DisplayTypeField = typeof DisplayTypeField[keyof typeof DisplayTypeField];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DisplayTypeField = {
  number: 'number',
  boost_percentage: 'boost_percentage',
  boost_number: 'boost_number',
  author: 'author',
  date: 'date',
  None: 'None',
} as const;

export interface DetailedCollectionModel {
  /** Category of the collection (e.g. PFPs, Memberships, Art) */
  category: string;
  /** Collection slug. A unique string to identify a collection on OpenSea */
  collection: string;
  contracts: CollectionContractModel[];
  /** Description of the collection */
  description?: string;
  /** External URL for the collection's Discord server */
  discord_url?: string;
  /** List of editor addresses for the collection */
  editors: string[];
  /** List of fees for the collection including creator earnings and OpenSea fees */
  fees: CollectionFeeModel[];
  /** Username for the collection's Instagram account */
  instagram_username?: string;
  /** If the collection is currently able to be bought or sold using OpenSea */
  is_disabled: boolean;
  /** If the collection is currently classified as 'Not Safe for Work' by OpenSea */
  is_nsfw: boolean;
  /** Name of the collection */
  name: string;
  /** OpenSea Link to collection */
  opensea_url: string;
  /** The unique public blockchain identifier, address, for the owner wallet. */
  owner: string;
  /** External URL for the collection's website */
  project_url?: string;
  safelist_status: SafelistRequestStatus;
  /** External URL for the collection's Telegram group */
  telegram_url?: string;
  /** If trait offers are currently being accepted for the collection */
  trait_offers_enabled: boolean;
  /** Username for the collection's Twitter account */
  twitter_username?: string;
  /** External URL for the collection's wiki */
  wiki_url?: string;
}

export interface DetailedAccountDataModel {
  /** The unique public blockchain identifier for the wallet. */
  address?: string;
  /** The OpenSea account's banner url. */
  banner_image_url?: string;
  /** The OpenSea account's bio. */
  bio?: string;
  /** Date the account was first added to OpenSea. */
  joined_date?: string;
  /** The OpenSea account's image url. */
  profile_image_url?: string;
  social_media_accounts?: SocialMediaAccountModel[];
  /** The OpenSea account's username. */
  username?: string;
  /** Personal website for the OpenSea user. */
  website?: string;
}

export interface Criteria {
  /** The collection in which the criteria offer is being made for. */
  collection: Collection;
  /** The unique public blockchain identifier, address, for the NFT contract */
  contract: Contract;
  /** Represents a list of token ids which can be used to fulfill the criteria offer. When decoded using the provided SDK function, developers can now see a list of all tokens that could be used to fulfill the offer. */
  encoded_token_ids?: string;
  /** The trait that the criteria offer is being made for. */
  trait?: Trait;
}

export type CreatedAtEnum = typeof CreatedAtEnum[keyof typeof CreatedAtEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatedAtEnum = {
  ' ': ' ',
} as const;

export interface DetailedNftModel {
  /** Link to the NFT's original animation. */
  animation_url?: string;
  /** Collection slug. A unique string to identify a collection on OpenSea */
  collection: string;
  /** The unique public blockchain identifier for the contract */
  contract: string;
  /** Deprecated Field */
  created_at?: CreatedAtEnum;
  /** The unique public blockchain identifier, wallet address, for the creator */
  creator: string;
  /** Description of the NFT */
  description: string;
  /** The NFT's unique identifier within the smart contract (also referred to as token_id) */
  identifier: string;
  /** Link to the NFT's original image. This may be an HTTP url, SVG data, or other directly embedded data. */
  image_url?: string;
  /** If the item is currently able to be bought or sold using OpenSea */
  is_disabled: boolean;
  /** If the item is currently classified as 'Not Safe for Work' by OpenSea */
  is_nsfw: boolean;
  /** If the item has been reported for suspicious activity by OpenSea */
  is_suspicious: boolean;
  /** Link to the offchain metadata store */
  metadata_url?: string;
  /** Name of the NFT */
  name: string;
  /** List of Owners. The field will be null if the NFT has more than 50 owners */
  owners: OwnerModel[];
  /** Rarity data for the NFT */
  rarity: RarityDataModel;
  /** ERC standard of the token (erc721, erc1155) */
  token_standard: string;
  /** List of Trait objects. The field will be null if the NFT has more than 50 traits */
  traits: TraitModel[];
  /** Last time that the NFT's metadata was updated by OpenSea */
  updated_at: string;
}

export interface CreateOfferResponse {
  order: SignedSimpleOrderV2;
}

export interface CreateListingResponse {
  order: SignedSimpleOrderV2;
}

export interface Contract {
  address: string;
}

export interface ConsiderationItem {
  /** When endAmount differs from `startAmount`, the realized amount is calculated linearly based on the time elapsed since the order became active. */
  endAmount: number;
  /** The ERC721 or ERC1155 token identifier or, in the case of a criteria-based item type, a merkle root composed of the valid set of token identifiers for the item. This value will be ignored for Ether and ERC20 item types, and can optionally be zero for criteria-based item types to allow for any identifier. */
  identifierOrCriteria: number;
  itemType: ItemType;
  /** The address which will receive the consideration item when the order is executed. */
  recipient: string;
  /** The amount of the token in question that will be required should the order be fulfilled. */
  startAmount: number;
  /** The item's token contract (with the null address used for native tokens) */
  token: string;
}

export interface ConsiderationInput {
  asset_contract_address: string;
  /** NFT Token ID which will be used to fulfill the offer. */
  token_id: string;
}

export interface GenerateOfferFulfillmentInput {
  /** If the offer you are fulfilling is a criteria offer, the NFT you are using to fulfill the offer with. The fulfiller account must own this NFT or the request will not succeed. */
  consideration?: ConsiderationInput;
  /** Fulfiller address. */
  fulfiller: FulfillerInput;
  /** Offer to get fullfillment data for. */
  offer: FulfillmentInput;
}

/**
 * * `affiliate` - affiliate
* `affiliate_partner` - affiliate_partner
* `affiliate_requested` - affiliate_requested
* `affiliate_blacklisted` - affiliate_blacklisted
* `verified` - verified
* `moderator` - moderator
* `staff` - staff
* `employee` - employee
 */
export type ConfigEnum = typeof ConfigEnum[keyof typeof ConfigEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConfigEnum = {
  affiliate: 'affiliate',
  affiliate_partner: 'affiliate_partner',
  affiliate_requested: 'affiliate_requested',
  affiliate_blacklisted: 'affiliate_blacklisted',
  verified: 'verified',
  moderator: 'moderator',
  staff: 'staff',
  employee: 'employee',
} as const;

export interface CollectionStatsModel {
  /** The all time average sale price of NFTs in the collection */
  average_price: number;
  /** The current lowest price of NFTs in the collection */
  floor_price: number;
  /** The symbol of the payment asset for the floor price */
  floor_price_symbol: string;
  /** The current market cap of the collection */
  market_cap: number;
  /** The current number of unique owners of NFTs in the collection */
  num_owners: number;
  /** The all time number of sales for the collection */
  sales: number;
  /** The all time volume of sales for the collection */
  volume: number;
}

/**
 * The interval for which the stats are calculated
 */
export type CollectionStatsInterval = typeof CollectionStatsInterval[keyof typeof CollectionStatsInterval];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollectionStatsInterval = {
  one_day: 'one_day',
  one_week: 'one_week',
  one_month: 'one_month',
} as const;

export interface CollectionStatsIntervalModel {
  /** The average sale price of NFTs in the collection during the interval */
  average_price: number;
  interval: CollectionStatsInterval;
  /** The number of sales for the collection during the specified interval */
  sales: number;
  /** The percentage change in number of sales compared to the previous interval */
  sales_diff: number;
  /** The volume of sales for the collection during the specified interval */
  volume: number;
  /** The percentage change in volume compared to the previous interval */
  volume_change: number;
  /** The volume differential compared to the previous interval */
  volume_diff: number;
}

export interface CollectionModel {
  /** Category of the collection (e.g. PFPs, Memberships, Art) */
  category: string;
  /** Collection slug. A unique string to identify a collection on OpenSea */
  collection: string;
  contracts: CollectionContractModel[];
  /** Description of the collection */
  description?: string;
  /** External URL for the collection's Discord server */
  discord_url?: string;
  /** Username for the collection's Instagram account */
  instagram_username?: string;
  /** If the collection is currently able to be bought or sold using OpenSea */
  is_disabled: boolean;
  /** If the collection is currently classified as 'Not Safe for Work' by OpenSea */
  is_nsfw: boolean;
  /** Name of the collection */
  name: string;
  /** OpenSea Link to collection */
  opensea_url: string;
  /** The unique public blockchain identifier, address, for the owner wallet. */
  owner: string;
  /** External URL for the collection's website */
  project_url?: string;
  safelist_status: SafelistRequestStatus;
  /** External URL for the collection's Telegram group */
  telegram_url?: string;
  /** If trait offers are currently being accepted for the collection */
  trait_offers_enabled: boolean;
  /** Username for the collection's Twitter account */
  twitter_username?: string;
  /** External URL for the collection's wiki */
  wiki_url?: string;
}

export interface CollectionFeeModel {
  /** Percentage of the sale price that is paid to the recipient */
  fee: number;
  /** The unique public blockchain identifier, address, for the recipient */
  recipient: string;
  /** If the fee is required for the collection */
  required?: boolean;
}

/**
 * Define the Contract's Addresses and Chain
 */
export interface CollectionContractModel {
  /** The unique public blockchain identifier, address, for the contract. */
  address: string;
  /** The chain on which the contract exists */
  chain: ChainIdentifier;
}

export interface Collection {
  /** Unique string to identify a collection on OpenSea. This can be found by visiting the collection on the OpenSea website and noting the last path parameter. */
  slug: string;
}

/**
 * OpenSea supported chains.
 */
export type ChainIdentifier = typeof ChainIdentifier[keyof typeof ChainIdentifier];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChainIdentifier = {
  ethereum: 'ethereum',
  matic: 'matic',
  klaytn: 'klaytn',
  bsc: 'bsc',
  solana: 'solana',
  arbitrum: 'arbitrum',
  arbitrum_nova: 'arbitrum_nova',
  avalanche: 'avalanche',
  optimism: 'optimism',
  base: 'base',
  zora: 'zora',
  sepolia: 'sepolia',
  rinkeby: 'rinkeby',
  mumbai: 'mumbai',
  baobab: 'baobab',
  bsctestnet: 'bsctestnet',
  goerli: 'goerli',
  soldev: 'soldev',
  arbitrum_goerli: 'arbitrum_goerli',
  avalanche_fuji: 'avalanche_fuji',
  optimism_goerli: 'optimism_goerli',
  base_goerli: 'base_goerli',
  zora_testnet: 'zora_testnet',
} as const;

/**
 * An enumeration.
 */
export type CategoryType = typeof CategoryType[keyof typeof CategoryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryType = {
  string: 'string',
  number: 'number',
} as const;

export type CancelEventModelEventTypeEnum = typeof CancelEventModelEventTypeEnum[keyof typeof CancelEventModelEventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancelEventModelEventTypeEnum = {
  cancel: 'cancel',
} as const;

export interface CancelEventModel {
  /** The chain on which the cancelled order originated */
  chain: ChainIdentifier;
  event_type?: CancelEventModelEventTypeEnum;
  /** Order hash for the order which was cancelled */
  order_hash: string;
}

export interface BuildOfferInput {
  /** Criteria for the collection or trait offer */
  criteria: Criteria;
  /** Builds the offer on OpenSea's signed zone to provide offer protections from receiving an item which is disabled from trading. */
  offer_protection_enabled?: boolean;
  /** The address which supplies all the items in the offer. */
  offerer: string;
  /** Exchange contract address. Must be one of ['0x00000000000000adc04c56bf30ac9d3c0aaf14dc'] */
  protocol_address: string;
  /** The number of offers to place. */
  quantity?: number;
}

export interface BuildOffer {
  /** Represents a list of token ids which can be used to fulfill the criteria offer. When decoded using the provided SDK function, developers can now see a list of all tokens that could be used to fulfill the offer. */
  encoded_token_ids?: string;
  /** Partial set of Seaport Order Parameters */
  partialParameters: PartialParameters;
}

export interface BasicListingPrice {
  current: PriceModel;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * Get an OpenSea Account Profile including details such as bio, social media usernames, and profile image.
 * @summary Get Account
 */
export const getAccount = (
    address: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<DetailedAccountDataModel>(
      {url: `/api/v2/accounts/${address}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get NFTs owned by a given account address.
 * @summary Get NFTs (by account)
 */
export const listNftsByAccount = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
    params?: ListNftsByAccountParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListNftsResponse>(
      {url: `/api/v2/chain/${chain}/account/${address}/nfts`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get a smart contract for a given chain and address.
 * @summary Get Contract
 */
export const getContract = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListNftsResponse>(
      {url: `/api/v2/chain/${chain}/contract/${address}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get multiple NFTs for a smart contract.
 * @summary Get NFTs (by contract)
 */
export const listNftsByContract = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
    params?: ListNftsByContractParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListNftsResponse>(
      {url: `/api/v2/chain/${chain}/contract/${address}/nfts`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get metadata, traits, ownership information, and rarity for a single NFT.
 * @summary Get an NFT
 */
export const getNft = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
    identifier: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetNftResponse>(
      {url: `/api/v2/chain/${chain}/contract/${address}/nfts/${identifier}`, method: 'GET'
    },
      options);
    }
  
/**
 * Refresh metadata for a single NFT.
 * @summary Refresh NFT Metadata
 */
export const refreshNft = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
    identifier: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<void>(
      {url: `/api/v2/chain/${chain}/contract/${address}/nfts/${identifier}/refresh`, method: 'POST'
    },
      options);
    }
  
/**
 * Get multiple NFTs for a collection.
 * @summary Get NFTs (by collection)
 */
export const listNftsByCollection = (
    collectionSlug: string,
    params?: ListNftsByCollectionParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListNftsResponse>(
      {url: `/api/v2/collection/${collectionSlug}/nfts`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get a list of OpenSea collections.
 * @summary Get Collections
 */
export const listCollections = (
    params?: ListCollectionsParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListCollectionsResponse>(
      {url: `/api/v2/collections`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get a single collection including details such as fees, traits, and links.
 * @summary Get a Collection
 */
export const getCollection = (
    collectionSlug: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<DetailedCollectionModel>(
      {url: `/api/v2/collections/${collectionSlug}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get stats for a single collection.
 * @summary Get Collection Stats
 */
export const getCollectionStats = (
    collectionSlug: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetCollectionStatsResponse>(
      {url: `/api/v2/collections/${collectionSlug}/stats`, method: 'GET'
    },
      options);
    }
  
/**
 * Get a list of events for an account. The list will be paginated and include up to 100 events per page.
 * @summary Get Events (by account)
 */
export const listEventsByAccount = (
    address: string,
    params?: ListEventsByAccountParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListEventsResponse>(
      {url: `/api/v2/events/accounts/${address}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get a list of events for a single NFT. The list will be paginated and include up to 100 events per page.
 * @summary Get Events (by NFT)
 */
export const listEventsByNft = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    address: string,
    identifier: string,
    params?: ListEventsByNftParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListEventsResponse>(
      {url: `/api/v2/events/chain/${chain}/contract/${address}/nfts/${identifier}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get a list of events for a collection. The list will be paginated and include up to 100 events per page.
 * @summary Get Events (by collection)
 */
export const listEventsByCollection = (
    collectionSlug: string,
    params?: ListEventsByCollectionParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<ListEventsResponse>(
      {url: `/api/v2/events/collection/${collectionSlug}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get all active, valid listings for a single collection.
 * @summary Get All Listings (by collection)
 */
export const getAllListingsOnCollectionV2 = (
    collectionSlug: string,
    params?: GetAllListingsOnCollectionV2Params,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<PaginatedListingList>(
      {url: `/api/v2/listings/collection/${collectionSlug}/all`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Retrieve all the information, including signatures, needed to fulfill a listing directly onchain.
 * @summary Fulfill a Listing
 */
export const generateListingFulfillmentDataV2 = (
    generateListingFulfillmentInput: GenerateListingFulfillmentInput,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<FulfillmentOutput>(
      {url: `/api/v2/listings/fulfillment_data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: generateListingFulfillmentInput
    },
      options);
    }
  
/**
 * Create a criteria offer to purchase any NFT in a collection or which matches the specified trait.
 * @summary Create Criteria Offer
 */
export const postCriteriaOfferV2 = (
    postCriteriaOfferInput: PostCriteriaOfferInput,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<Offer>(
      {url: `/api/v2/offers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCriteriaOfferInput
    },
      options);
    }
  
/**
 * Build a portion of a criteria offer including the merkle tree needed to post an offer.
 * @summary Build an Offer
 */
export const buildOfferV2 = (
    buildOfferInput: BuildOfferInput,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<BuildOffer>(
      {url: `/api/v2/offers/build`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: buildOfferInput
    },
      options);
    }
  
/**
 * Get the active, valid collection offers for the specified collection.
 * @summary Get Collection Offers
 */
export const getCollectionOffersV2 = (
    collectionSlug: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<OfferList>(
      {url: `/api/v2/offers/collection/${collectionSlug}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get all active, valid offers for the specified collection. This includes individual and criteria offers.
 * @summary Get All Offers (by collection)
 */
export const getAllOffersOnCollectionV2 = (
    collectionSlug: string,
    params?: GetAllOffersOnCollectionV2Params,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<PaginatedOfferList>(
      {url: `/api/v2/offers/collection/${collectionSlug}/all`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get the active, valid trait offers for the specified collection.
 * @summary Get Trait Offers
 */
export const getTraitOffersV2 = (
    collectionSlug: string,
    params?: GetTraitOffersV2Params,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<OfferList>(
      {url: `/api/v2/offers/collection/${collectionSlug}/traits`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Retrieve all the information, including signatures, needed to fulfill an offer directly onchain.
 * @summary Fullfill an Offer
 */
export const generateOfferFulfillmentDataV2 = (
    generateOfferFulfillmentInput: GenerateOfferFulfillmentInput,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<FulfillmentOutput>(
      {url: `/api/v2/offers/fulfillment_data`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: generateOfferFulfillmentInput
    },
      options);
    }
  
/**
 * Get the complete set of active, valid listings.
 * @summary Get Listings
 */
export const getListings = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    protocol: 'seaport',
    params?: GetListingsParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetListingsResponse>(
      {url: `/api/v2/orders/${chain}/${protocol}/listings`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * List a single NFT (ERC721 or ERC1155) for sale on the OpenSea marketplace.
 * @summary Create Listing
 */
export const postListing = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    protocol: 'seaport',
    orderInputWithProtocol: OrderInputWithProtocol,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<CreateListingResponse>(
      {url: `/api/v2/orders/${chain}/${protocol}/listings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderInputWithProtocol
    },
      options);
    }
  
/**
 * Get the active, valid individual offers. This does not include criteria offers.
 * @summary Get Individual Offers
 */
export const getOffers = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    protocol: 'seaport',
    params?: GetOffersParams,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetOfferResponse>(
      {url: `/api/v2/orders/${chain}/${protocol}/offers`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Create an offer to purchase a single NFT (ERC721 or ERC1155).
 * @summary Create Individual Offer
 */
export const postOffer = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    protocol: 'seaport',
    orderInputWithProtocol: OrderInputWithProtocol,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<CreateOfferResponse>(
      {url: `/api/v2/orders/${chain}/${protocol}/offers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderInputWithProtocol
    },
      options);
    }
  
/**
 * Get a single order, offer or listing, by its order hash. Protocol and Chain are required to prevent hash collisions.
 * @summary Get Order
 */
export const getOrder = (
    chain: 'arbitrum' | 'arbitrum_goerli' | 'arbitrum_nova' | 'avalanche' | 'avalanche_fuji' | 'baobab' | 'base' | 'base_goerli' | 'bsc' | 'bsctestnet' | 'ethereum' | 'goerli' | 'klaytn' | 'matic' | 'mumbai' | 'optimism' | 'optimism_goerli' | 'sepolia' | 'solana' | 'soldev' | 'zora' | 'zora_testnet',
    protocolAddress: 0x00000000000000adc04c56bf30ac9d3c0aaf14dc,
    orderHash: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetOrderResultVal>(
      {url: `/api/v2/orders/chain/${chain}/protocol/${protocolAddress}/${orderHash}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get the traits in a collection.
 * @summary Get Traits
 */
export const getTraits = (
    collectionSlug: string,
 options?: SecondParameter<typeof axiosClientOpensea>,) => {
      return axiosClientOpensea<GetTraitResponse>(
      {url: `/api/v2/traits/${collectionSlug}`, method: 'GET'
    },
      options);
    }
  
export type GetAccountResult = NonNullable<Awaited<ReturnType<typeof getAccount>>>
export type ListNftsByAccountResult = NonNullable<Awaited<ReturnType<typeof listNftsByAccount>>>
export type GetContractResult = NonNullable<Awaited<ReturnType<typeof getContract>>>
export type ListNftsByContractResult = NonNullable<Awaited<ReturnType<typeof listNftsByContract>>>
export type GetNftResult = NonNullable<Awaited<ReturnType<typeof getNft>>>
export type RefreshNftResult = NonNullable<Awaited<ReturnType<typeof refreshNft>>>
export type ListNftsByCollectionResult = NonNullable<Awaited<ReturnType<typeof listNftsByCollection>>>
export type ListCollectionsResult = NonNullable<Awaited<ReturnType<typeof listCollections>>>
export type GetCollectionResult = NonNullable<Awaited<ReturnType<typeof getCollection>>>
export type GetCollectionStatsResult = NonNullable<Awaited<ReturnType<typeof getCollectionStats>>>
export type ListEventsByAccountResult = NonNullable<Awaited<ReturnType<typeof listEventsByAccount>>>
export type ListEventsByNftResult = NonNullable<Awaited<ReturnType<typeof listEventsByNft>>>
export type ListEventsByCollectionResult = NonNullable<Awaited<ReturnType<typeof listEventsByCollection>>>
export type GetAllListingsOnCollectionV2Result = NonNullable<Awaited<ReturnType<typeof getAllListingsOnCollectionV2>>>
export type GenerateListingFulfillmentDataV2Result = NonNullable<Awaited<ReturnType<typeof generateListingFulfillmentDataV2>>>
export type PostCriteriaOfferV2Result = NonNullable<Awaited<ReturnType<typeof postCriteriaOfferV2>>>
export type BuildOfferV2Result = NonNullable<Awaited<ReturnType<typeof buildOfferV2>>>
export type GetCollectionOffersV2Result = NonNullable<Awaited<ReturnType<typeof getCollectionOffersV2>>>
export type GetAllOffersOnCollectionV2Result = NonNullable<Awaited<ReturnType<typeof getAllOffersOnCollectionV2>>>
export type GetTraitOffersV2Result = NonNullable<Awaited<ReturnType<typeof getTraitOffersV2>>>
export type GenerateOfferFulfillmentDataV2Result = NonNullable<Awaited<ReturnType<typeof generateOfferFulfillmentDataV2>>>
export type GetListingsResult = NonNullable<Awaited<ReturnType<typeof getListings>>>
export type PostListingResult = NonNullable<Awaited<ReturnType<typeof postListing>>>
export type GetOffersResult = NonNullable<Awaited<ReturnType<typeof getOffers>>>
export type PostOfferResult = NonNullable<Awaited<ReturnType<typeof postOffer>>>
export type GetOrderResult = NonNullable<Awaited<ReturnType<typeof getOrder>>>
export type GetTraitsResult = NonNullable<Awaited<ReturnType<typeof getTraits>>>
