import { Box } from "@mui/material"
import { Log } from "../hooks/useLogs"
import { FC } from "react";
import moment from "moment";

interface LogItemProps {
    log: Log;
}

const getColorByLogType = (type: Log["type"]) => {
    switch (type) {
        case "info":
            return "#CC978E";
        case "error":
            return "#FF3864";
        case "success":
            return "#8ED081";
        default:
            return "#CC978E";
    }
}

const LogItem: FC<LogItemProps> = ({ log }) => {

    return (
        <Box sx={{ display: "flex", gap: "6px" }}>
            <Box sx={{ color: getColorByLogType(log.type) }}>{log.type}:</Box>
            <Box>{log.message}</Box>
            <Box sx={{ fontSize: "12px", mt: "auto", color: "gray" }}>{moment(log.timestamp).format("YYYY-MM-DD HH:mm:ss")}</Box>    
        </Box>
    )
}

interface LogsProps {
    logItems: Log[];
}

export const Logs: FC<LogsProps> = ({ logItems }) => {
    return (
        <Box ml={2} mt={1} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Box mb={1} sx={{ fontSize: "24px", fontWeight: "bold" }}>Logs:</Box>
            {
                logItems.map((log, index) => (
                    <LogItem key={index} log={log} />
                ))
            }
        </Box>
    )
}
